@import url("https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&family=Josefin+Sans:wght@400;500;700&family=Manrope:wght@200..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Inter Tight", sans-serif;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  line-height: 110%;
  scroll-behavior: smooth;
}
html,
body {
  overflow-x: hidden;
  background-color: #f9f9f9;
}
body {
  position: relative;
}
.text-size {
  font-size: clamp(16vw, 17vw, 18vw);
}
.text-size-contact {
  font-size: clamp(7vw, 14vw, 17vw);
}
.bg-white-gradient {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(248, 242, 242, 0.845) 0%,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(240, 239, 239, 0.31) 0%,
    rgba(255, 255, 255, 0.334) 99%
  );
}

.hoverSocialIconBg {
  background-color: white;
}
.shadow-1 {
  box-shadow: rgba(50, 50, 93, 0.3) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
